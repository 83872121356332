import { Engine, WebGPUEngine, Scene, SceneLoader, HemisphericLight, Vector3, ArcRotateCamera, AbstractMesh, Mesh, Animation, CubicEase, EasingFunction, AbstractEngine } from '@babylonjs/core'

export interface CameraConfig {
  alpha: number
  beta: number
  radius: number
  lowerAlphaLimit: number
  upperAlphaLimit: number
  lowerRadiusLimit: number
  upperRadiusLimit: number
  upperBetaLimit : number
  lowerBetaLimit : number
  panningSensibility: number
  panningDistanceLimit?: number
}

export interface Node {
  id: string
  meshName: string
}

export interface MapConfig {
  canvas: HTMLCanvasElement
  sceneUrl: string
  cameraConfig: CameraConfig
  useWebGPU: boolean,
  nodes: Node[]
}

export default class MapEngine {
  private camera: any
  private scene: any
  private meshes: AbstractMesh[]
  private onNodeClick: any
  private isActivatedCameraLimit = true
  private engine: AbstractEngine

  constructor(private config: MapConfig) {}

  public async start(onNodeClick, onLoaded){
    this.onNodeClick = onNodeClick
    const { canvas, sceneUrl, cameraConfig, useWebGPU } = this.config
    this.engine = await initAndGetEngine(canvas, useWebGPU)
    const scene = new Scene(this.engine)
    let perimeterLimits = null

    SceneLoader.ImportMesh('', sceneUrl, '', scene, (_meshes) => {
      const cameraLimit = this.getCameraLimit(_meshes)
      cameraLimit?.setEnabled?.(false)
      spinObject(cameraLimit, 'y', degreesToRadians(90))
      
      this.meshes = _meshes
        .filter(m => m?.name?.includes("_primitive") && m?.name?.includes("Node_") )
        .filter(m => this.findNodeByMeshName(m?.name))
      perimeterLimits = getPerimeterLimits(cameraLimit)
      onLoaded?.()
    })

    const camera = createArcRotateCamera(scene, cameraConfig)
    attachCameraControls(camera, canvas, cameraConfig)

    scene.registerBeforeRender(() => {

      if(!perimeterLimits || !this.isActivatedCameraLimit) return 

      const bounceFactor = this.camera.panningSensibility / 1000

      const { x: previousX, z: previousZ } = this.camera.target
      const { minLimit, maxLimit } = perimeterLimits
      const radius = this.camera.radius / 2

      const min = new Vector3(minLimit.x + radius, minLimit.y, minLimit.z + radius)
      const max = new Vector3(maxLimit.x - radius, maxLimit.y, maxLimit.z - radius)

      if (this.camera.target.x < min.x || this.camera.target.x > max.x) {
          this.camera.target.x = applyBounceEffect(this.camera.target.x, previousX, min.x, max.x, bounceFactor)
      }

      if (this.camera.target.z < min.z || this.camera.target.z > max.z) {
          this.camera.target.z = applyBounceEffect(this.camera.target.z, previousZ, min.z, max.z, bounceFactor)
      }
  })
    
    scene.onPointerDown = (evt: any, pickResult: any) => {
      if (pickResult.hit && this.meshes?.includes?.(pickResult.pickedMesh)) {
        const mesh = pickResult.pickedMesh
        this.isActivatedCameraLimit = false
        const node = this.findNodeByMeshName(mesh?.name)
        onNodeClick(node)
      }
    }

    new HemisphericLight("light1", new Vector3(1, 1, 0), scene)

    this.engine.runRenderLoop(() => {
      scene.render()
    })

    window.addEventListener('resize', () => {
      this.engine.resize()
    })
    
    this.scene = scene
    this.camera = camera
  }

  private findNodeByMeshName(meshName: string){
    return this.config?.nodes?.find(n => meshName?.includes?.(n?.meshName))
  }

  public clickNodeByMeshName(meshName: string, nodeOffset) {
    const mesh = this.meshes?.find(m => m.name.includes(meshName))
    if(!mesh) return
    this.isActivatedCameraLimit = false
    this.camera.detachControl()
    focusOnObject(this.camera, mesh, this.scene, nodeOffset)
    const node = this.findNodeByMeshName(mesh?.name)
    this.onNodeClick(node)
  }

  public focusObject(name: string, nodePositionOffset){

    const mesh = this.meshes?.find(m => m.name.includes(name))
    if(mesh) {
      this.isActivatedCameraLimit = false
      this.camera.detachControl()
      focusOnObject(this.camera, mesh, this.scene, nodePositionOffset)
    }
  }

  public resetCamera(meshName: string, nodePositionOffset){ 
    if(!this.scene || !this.camera || !this.camera?.upperRadiusLimit) return 

    this.isActivatedCameraLimit = true
    const mesh = this.meshes?.find(m => m.name.includes(meshName))

    if(mesh) showTargetPosition(this.scene, this.camera, mesh?.getAbsolutePosition?.(), { radius: this.camera.upperRadiusLimit})

    const { canvas, cameraConfig } = this.config
    if(canvas) attachCameraControls(this.camera, canvas, cameraConfig)
  }

  private getCameraLimit(meshes: AbstractMesh[]){
    return meshes?.find?.(m => m.name === 'Camera_Limite') 
  }

  public shutdown(){
    this.engine?.stopRenderLoop()
    this.scene?.dispose()
    this.engine.dispose()
  }
}

const getPerimeterLimits = (cameraLimitMesh: AbstractMesh) => {
  const boundingInfo = cameraLimitMesh.getBoundingInfo()
  const boundingBox = boundingInfo.boundingBox
  const min = boundingBox.minimumWorld
  const max = boundingBox.maximumWorld
  return {
      minLimit: min,
      maxLimit: max
  }
}

const applyBounceEffect = (targetValue, previousValue, minLimit, maxLimit, bounceFactor) => {
  const clampedValue = BABYLON.Scalar.Clamp(targetValue, minLimit, maxLimit)
  return targetValue + (clampedValue - previousValue) * bounceFactor
}

const degreesToRadians = (degrees: number) => degrees * (Math.PI / 180)

const spinObject = (mesh: BABYLON.Mesh, axis: 'x' | 'y' | 'z', angle: number) => {
  const rotationAxis = 
    axis === 'x' ? new BABYLON.Vector3(1, 0, 0) :
    axis === 'y' ? new BABYLON.Vector3(0, 1, 0) :
    new BABYLON.Vector3(0, 0, 1)

  mesh.rotate(rotationAxis, angle, BABYLON.Space.LOCAL)
}

const createArcRotateCamera = (
  scene: Scene, 
  { 
    beta,
    alpha,
    radius
  }: CameraConfig) => {
  return new ArcRotateCamera('camera', alpha, beta, radius, Vector3.Zero(), scene)
}

const attachCameraControls = (
  camera: ArcRotateCamera,
  canvas: HTMLCanvasElement,
  { 
    panningSensibility,
    lowerAlphaLimit,
    upperAlphaLimit,
    upperBetaLimit,
    lowerBetaLimit,
    lowerRadiusLimit,
    panningDistanceLimit,
    upperRadiusLimit,
  }: CameraConfig) => {
  camera.attachControl(canvas, true)
  camera.lowerRadiusLimit     = lowerRadiusLimit     ?? camera.lowerRadiusLimit
  camera.upperRadiusLimit     = upperRadiusLimit     ?? camera.upperRadiusLimit
  camera.upperBetaLimit       = upperBetaLimit       ?? camera.upperBetaLimit
  camera.lowerBetaLimit       = lowerBetaLimit       ?? camera.lowerBetaLimit
  camera.lowerAlphaLimit      = lowerAlphaLimit      ?? camera.lowerAlphaLimit
  camera.upperAlphaLimit      = upperAlphaLimit      ?? camera.upperAlphaLimit
  camera.panningSensibility   = panningSensibility   ?? camera.panningSensibility
  camera.panningDistanceLimit = panningDistanceLimit ?? camera.panningDistanceLimit

  setIsometricMovement(camera)
}


const setIsometricMovement = (camera: ArcRotateCamera) => {
	const basePanningSensibility = camera.panningSensibility
	camera._useCtrlForPanning  = false
	camera._panningMouseButton = 0
	camera.panningAxis = new Vector3(1, 0, 1) // change le panning axis de y à z
	const updatePanningSensitivity= () => {
		camera.panningSensibility = Math.max(100, basePanningSensibility / camera.radius)
	}
	camera.onViewMatrixChangedObservable.add(updatePanningSensitivity)
}


const checkWebGPUSupport = async () => {
	if (!WebGPUEngine?.IsSupportedAsync) 
	  return false
  
	try {
	  return await WebGPUEngine?.IsSupportedAsync
  
	} catch (e) {
	  console.error("Error checking WebGPU support:", e)
	  return false
	}
}
  
const initAndGetEngine = async (canvas: HTMLCanvasElement, useWebGpu = true) => {
  // try {
  //   const engine = new WebGPUEngine(canvas)
  //   await engine.initAsync()
  //   console.log("WebGPU engine initialized")
  //   return engine
  // } catch(e) {
  //   console.log("WebGL engine initialized")
  //   return new Engine(canvas)
  // }

	if(useWebGpu) {
	  const supportsWebGPU = await checkWebGPUSupport()
	  if (supportsWebGPU) {
		  const engine = new WebGPUEngine(canvas)
		  await engine.initAsync()
		  console.log("WebGPU engine initialized")
		  return engine
	  }
	}
  
	console.log("WebGL engine initialized")
	return new Engine(canvas)
}


function focusOnObject(camera, targetMesh, scene, offset) {  
	const targetPosition = targetMesh.getAbsolutePosition().clone()
	targetPosition.x += offset?.x
	targetPosition.z += offset?.z
	showTargetPosition(scene, camera, targetPosition, { radius: camera.lowerRadiusLimit})
}

const showTargetPosition = (scene, camera, targetPosition, { radius }) => {

	const easingFunction = new CubicEase()
	easingFunction.setEasingMode(EasingFunction.EASINGMODE_EASEOUT)

	const frameRate = 30 * 1
	const slide = new Animation(
		"slide", 
		"target", 
		frameRate, 
		Animation.ANIMATIONTYPE_VECTOR3, 
		Animation.ANIMATIONLOOPMODE_CONSTANT
	)
	const keyFrames = []
	keyFrames.push({ frame: 0, value: camera.target.clone() })
	keyFrames.push({ frame: frameRate, value: targetPosition })
	slide.setKeys(keyFrames)
	slide.setEasingFunction(easingFunction)

	const zoom = new Animation(
		"zoom", 
		"radius", 
		frameRate, 
		Animation.ANIMATIONTYPE_FLOAT, 
		Animation.ANIMATIONLOOPMODE_CONSTANT
	)
	
	zoom.setKeys([
		{ frame: 0, value: camera.radius },
		{ frame: frameRate, value: radius }
	])

	zoom.setEasingFunction(easingFunction)

  const animations = [zoom]
  if(camera.target !== targetPosition) animations.push(slide)
	
  camera.animations = animations

	scene.beginAnimation(camera, 0, frameRate, false)
}