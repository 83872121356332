let state = null

export default function useProduct () {
  if(state) return state

  const selectedProduct = ref(null)

  state = {
    selectedProduct,
    selectProductById: productId =>  {
      if(!productId) return
      
      const { findById } = useProducts()
      selectedProduct.value = findById(productId)
    }
  }

  return state
}