<template>
	<div class="flex gap-2">
		<div :style="productImageStyle" class="bg-[length:96px_96px] size-[96px] rounded-md"></div>
		<div class="flex-1 flex flex-col gap-2">
			<div>
				<div class="text-dark text-2xl uppercase">{{ product.title }}</div>
				<div class="subtitle mt-[-2px] mb-[2px]">by {{ product.subtitle }}</div>
				<hr class="hr" />
			</div>
			<div class="flex justify-between items-center">
				<div class="flex gap-1.5 items-center">
					<div class="logo"></div>
					<div class="cat-pill">{{ product.category }}</div>
				</div>
				<UiIconButtons :icons="icons ?? []" :id="product.id" :icon-types="iconTypes"/>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
import cartIcon from '@/assets/img/icons/cart.svg'
import likeIcon from '@/assets/img/icons/like.svg'

const route = useRoute()
const { findById } = useProducts()

const product = findById(route.params.id)

const icons = [cartIcon, likeIcon]

const iconTypes = ['cart', 'like']

const productImageStyle = computed(() => ({
	backgroundImage: `url(${product.image})`
}))
</script>

<style lang="stylus" scoped>
</style>