<template>
	<div class="flex items-center p-1">
		<div class="bg-[url('assets/img/users/user-1.svg')] bg-[length:84px_84px] bg-no-repeat bg-center size-[84px]"></div>

		<div :class="['bg-white flex-1 flex items-center justify-between p-2 rounded-r-lg', borderClass]">
			<div class="font-semibold pl-2">{{ name }}</div>
			<div class="flex gap-4 items-center">
				<UiStar fillColor="#ECC785" width="28px" height="28px" class="bg-dark rounded-full p-1" />
				<div class="font-semibold pr-2 w-[32px]">{{ score }}</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
const props = defineProps({
	name: {
		type: String,
		required: true,
	},
	score: {
		type: Number,
		required: true,
	},
	border: {
		type: String,
		default: '',
	}
})

const borderClass = computed(() => props.border)
</script>

<style scoped></style>
