<template>
	<div class="overflow-auto">
		<UiQuizHeader :title="headerTitle" />
		<transition name="slide-fade" mode="out-in">
			<div :key="isCompleted" class="text-center uppercase pt-2 h-[calc(100dvh-320px)] flex flex-start overflow-auto">
				<div class="w-[292px] m-auto flex flex-col justify-evenly gap-8">
					<div class="text-[22px] leading-[30px]">
						<div v-if="!isCompleted">
							<span class="font-bold">{{ textContent.questionPrefix }}</span>
							{{ textContent.questionMiddle }}
							<span class="font-bold">{{ company }}</span>
							<span>{{ textContent.questionSuffix }}</span>
						</div>
						<div v-else>{{ tryAgainMessage }}</div>
					</div>
					<div class="flex gap-[34px] justify-center items-center py-6">
						<UiStarScore :score="isCompleted ? score : bestScore" :animate="isCompleted" />
					</div>
					<div class="flex flex-col gap-8">
						<UiButton height="64px" bgColor="bg-dark" fontColor="text-white" @click="handleStartClick">{{ startButtonText }}</UiButton>
						<UiButton height="40px" bgColor="bg-yellow" fontColor="text-dark" @click="showLeaderboard">{{ textContent.leaderboardBtnText }}</UiButton>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script lang="ts" setup>
const props = defineProps({
	company: {
		type: String,
		required: true,
	},
	isCompleted: {
		type: Boolean,
		default: false
	},
	score: {
		type: Number,
		default: 0
	},
	bestScore: {
		type: Number,
		default: 0
	}
})

const emit = defineEmits(['startTrivia', 'showLeaderboard', 'resetQuiz'])

const textContent = {
	questionPrefix: 'Do you know',
	questionMiddle: ' everything about ',
	questionSuffix: '’s processes?',
	startBtnText: 'Get started!',
	tryAgainBtnText: 'Try again!',
	leaderboardBtnText: 'Leaderboard'
}

const tryAgainMessage = computed(() => {
	if (props.score === 0) {
		return 'Give it another try and see how much you can learn.'
	} else if (props.score === 1) {
		return 'Good effort! Keep exploring to improve your score.'
	} else if (props.score === 2) {
		return 'Well done! You\'re getting there. Keep it up to reach the top!'
	} else if (props.score === 3) {
		return 'Fantastic job! You\'re a knowledgemaster!'
	}
	return 'Try again!'
})

const startButtonText = computed(() => {
	return props.isCompleted ? textContent.tryAgainBtnText : textContent.startBtnText
})

const headerTitle = computed(() => {
	return props.isCompleted ? 'Trivia' : 'Quiz!'
})

function handleStartClick() {
	if (props.isCompleted) {
		emit('resetQuiz')
	} else {
		emit('startTrivia')
	}
}

function showLeaderboard() {
	emit('showLeaderboard')
}
</script>

<style lang='stylus' scoped>
@import '@/assets/css/transitions.css'

.slide-fade-enter-active,
.slide-fade-leave-active 
	transition transform 0.7s cubic-bezier(0.165, 0.840, 0.440, 1.000), opacity 0.7s cubic-bezier(0.165, 0.840, 0.440, 1.000)

</style>
