<template>
	<div class="relative">
		<UiCompanyHeader :showSearchbar="showSearchbar" :company="companyName" :companyImage="companyImage" :recordedProducts="recordedProducts"
			class="absolute top-[-128px] left-[-16px] w-[100vw] text-white bg-dark min-h-[130px] py-2" />
		<UiQuizHeader :title="headerTitle" :bgColor="bgColor" :fontColor="fontColor" />
		<div class="pr-[6px] mr-[-6px] py-3 h-[calc(100dvh-320px)] overflow-auto">
			<div v-for="(user, index) in users" :key="user.name">
				<UiQuizUser :name="user.name" :score="user.score" :border="getBorderColor(index)" />
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
import useProducts from '@/composables/useProducts'
import useCompanies from '@/composables/useCompanies'

defineProps({
	companyName: {
		type: String,
		required: true,
	},
	showSearchbar: {
		type: Boolean,
		default: false,
	},
})
const headerTitle = 'Leaderboard'
const bgColor = 'bg-yellow'
const fontColor = 'bg-dark'
const route = useRoute()
const productId = route.params.id
const { findById: findProductById, findAll: findAllProducts } = useProducts()
const { findAll: findAllCompanies } = useCompanies()
const product = findProductById(productId)
const companyName = product?.subtitle || ''
const companies = findAllCompanies()
const company = companies.find(company => company.title === companyName)
const companyImage = company?.image || ''
const products = findAllProducts()
const recordedProducts = products.filter(p => p.subtitle === companyName).length

const users = [
	{ name: 'Emily H.', score: 72 },
	{ name: 'Daniel K.', score: 85 },
	{ name: 'Sophia L.', score: 90 },
	{ name: 'Maya P.', score: 128 },
	{ name: 'Ethan R.', score: 65 },
	{ name: 'Chloe S.', score: 88 },
	{ name: 'Isabella T.', score: 74 },
	{ name: 'Lucas M.', score: 81 }
].sort((a, b) => b.score - a.score)

const getBorderColor = (index) => {
	if (index === 0) return 'gold'
	if (index === 1) return 'silver'
	if (index === 2) return 'bronze'
	return ''
}
</script>

<style lang="stylus">
	.gold 
		border 5px solid #ECC785
		border-left none
		padding-right 2.5px

	.silver 
		border 5px solid #C7C7C7
		border-left none
		padding-right 2.5px

	.bronze 
		border 5px solid #EC9E85
		border-left none
		padding-right 2.5px
</style>