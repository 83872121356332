<template>
	<div class="relative flex justify-between mb-[-8px] pt-[8px]">
		<div class="absolute top-[-8px] left-0 w-full h-full">
			<div class="absolute top-2 rounded-full bg-dark"
				:style="{ width: '6px', height: '6px', left: dotLeft + 'px', transition: 'left 0.5s cubic-bezier(0.165, 0.840, 0.440, 1.000)' }"
			></div>
		</div>

		<div v-for="item in items" :key="item.name" class="flex flex-col items-center relative"	@click="select(item.name)">
			<div :class="item.iconClass" class="bg-no-repeat bg-center size-[48px] cursor-pointer"></div>
			<div class="text-center text-sm">{{ item.label }}</div>
		</div>
	</div>
</template>

<script lang="ts" setup>

const props = defineProps({
	current: {
		type: String,
		required: true
	}
})

const emit = defineEmits(['select'])

const items = [
	{ name: 'Map', label: 'Map', iconClass: "bg-[url('assets/img/icons/map-menu/pin-menu-icon.svg')] bg-[length:40px_width:40px]" },
	{ name: 'Story', label: 'Play Story', iconClass: "bg-[url('assets/img/icons/map-menu/play-menu-icon.svg')] bg-[length:40px_width:40px]" },
	{ name: 'Facts', label: 'Facts', iconClass: "bg-[url('assets/img/icons/map-menu/facts-menu-icon.svg')] bg-[length:40px_width:40px]" },
	{ name: 'Quiz', label: 'Quiz', iconClass: "bg-[url('assets/img/icons/map-menu/star-menu-icon.svg')] bg-[length:40px_width:40px]" }
]

const select = (componentName) => {
	emit('select', componentName)
}

const dotLeft = ref(0)

const updateDotPosition = async () => {
	await nextTick()
	const itemsList = document.querySelectorAll('.flex.flex-col.items-center')
	const currentItemIndex = items.findIndex(item => item.name === props.current)
	if (currentItemIndex >= 0 && itemsList.length > currentItemIndex) {
		const item = itemsList[currentItemIndex] as HTMLElement
		dotLeft.value = item.offsetLeft + (item.offsetWidth / 2) - 3
	}
}

watch(() => props.current, updateDotPosition, { immediate: true })
</script>

<style></style>