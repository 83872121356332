import { Tools } from "@babylonjs/core";
import usMapOverlay from "./useMapOverlay";

let state = null

export const useMap = () => {
	if(state) return state

	const { clientRect } = usMapOverlay()
	const selectedNode = ref<Node | null | undefined>(null)
	const showMap = ref(false)
	const showPreviousNode = ref(false)
	const showNextNode = ref(false)
	const nodes = ref([])
	const paginationText = ref('Node')

	let mapEngine = null
	let isFucusFirstNode = false
	let canvas = null
	const focusFirstNode = () => {
		isFucusFirstNode = true
		const nodePositionOffset = getNodePositionOffset(clientRect, canvas)
		mapEngine?.clickNodeByMeshName(nodes.value?.[0]?.meshName, nodePositionOffset)
	}
	
	const onLoadStart = () => showMap.value = false
	
	const getStartNode = () => nodes.value?.[1]

	const onLoadEnd = _mapEngine => {
		mapEngine = _mapEngine
		showMap.value = true
		if(isFucusFirstNode) focusFirstNode()
		else mapEngine.resetCamera(getStartNode()?.meshName, {x: 0, z: 0})
	}

	const updatePaginationText = selectedNode => {		
		paginationText.value = `Node ${selectedNode?.value?.id}/${nodes.value?.length}`
	}

	const getNodePositionOffset = (clientRect, canvas) => {
		const canvasClientRect = canvas?.getBoundingClientRect?.()
		if (!clientRect || !canvasClientRect) return {x: 0, y: 0}

		const worldHeight = 33
		const halfWorldHeight = worldHeight / 2

		const halfCanvasHeight = canvasClientRect.height / 2
		const cardHeight = unref(clientRect)?.height + 16
		const isUpSide = halfCanvasHeight <= cardHeight
		const pxToWorld = (px, worldSizeY, totalPx) => (worldSizeY * px) / totalPx
		
		const offsetY = isUpSide ? 
			pxToWorld(cardHeight - halfCanvasHeight, halfWorldHeight, halfCanvasHeight) : 
			pxToWorld(halfCanvasHeight - cardHeight, halfWorldHeight, halfCanvasHeight)
		
		const finalOffsetY = ((halfWorldHeight - offsetY) / 2) + offsetY

		return {
			x: -finalOffsetY,
			z: finalOffsetY
		}
	}

	watch(() => clientRect, (clientRect) => {
		const idx = nodes.value?.findIndex(n => n === selectedNode.value)
		showPreviousNode.value = idx > 0
		showNextNode.value = idx < nodes.value?.length - 1
	
		const nodePositionOffset = getNodePositionOffset(clientRect, canvas)
	
		mapEngine?.focusObject(selectedNode?.value?.meshName, nodePositionOffset)
		updatePaginationText(selectedNode)
	}, { immediate: true, deep: true })	
	
	watch(() => selectedNode, selectedNode => {
		updatePaginationText(selectedNode)
	}, { immediate: true, deep: true })

	const wrapLoadMap = ({ canvas: _canvas, product }) => {
		nodes.value = product?.mapNodes
		canvas = _canvas
		return loadMap((node: Node) => selectedNode.value = node, cameraIsometricProps, onLoadStart, onLoadEnd)({ canvas: _canvas, product })
	}

	state = {
		loadMap: wrapLoadMap,
		showMap,
		selectedNode,
		paginationText,
		showNextNode,
		showPreviousNode,
		focusFirstNode,
		restartCameraPosition: () => {
			isFucusFirstNode = false
			mapEngine?.resetCamera(getStartNode()?.meshName, {x: 0, z: 0})
			selectedNode.value = null
		},
		unMounted: () => {
			state = null
			isFucusFirstNode = false
			mapEngine?.shutdown()
		},
		nextNode: () => {
			const unrefNodes = unref(nodes)
			if(!unrefNodes) return
			
			const unrefSelectedNode = unref(selectedNode)
			const idx = unrefNodes?.findIndex(n => n === unrefSelectedNode)
			if(idx === unrefNodes?.length - 1) return
			else selectedNode.value = unrefNodes[idx + 1]
		},
		previousNode: () => {
			const unrefNodes = unref(nodes)
			if(!unrefNodes) return
			
			const unrefSelectedNode = unref(selectedNode)
			const idx = unrefNodes?.findIndex(n => n === unrefSelectedNode)
			if(idx === 0) return
			else selectedNode.value = unrefNodes[idx - 1]
		},
		unselectNode: () => {
			selectedNode.value = null
			mapEngine?.resetCamera(getStartNode()?.meshName, {x: 0, z: 0})
			isFucusFirstNode = false
		}
	}	

	return state
}

const loadMap = (onSelectNode, cameraConfig, onLoadStart, onLoadEnd) => ({ canvas, product }) => {
	const mapUrl = product?.mapUrl
	const nodes  = product?.mapNodes ?? []
	if(canvas == null || mapUrl == null) return

	onLoadStart?.()

	const engine = new MapEngine({
		cameraConfig: cameraConfig,
		canvas,
		sceneUrl: mapUrl,
		useWebGPU: true,
		nodes: <Node[]> nodes
	})

	engine.start((node: any) => {
		onSelectNode(node)
	}, () => {
		onLoadEnd?.(engine)
	})
}

const alpha = Tools.ToRadians(135)
const beta  = Tools.ToRadians(35)
const upperRadiusLimit = 70
const cameraIsometricProps: CameraConfig = {
	alpha,
	beta,
	panningSensibility: 2000,
	lowerAlphaLimit: alpha,
	upperAlphaLimit: alpha,
	lowerRadiusLimit: 40,
	upperRadiusLimit: upperRadiusLimit,
	upperBetaLimit : beta,
	lowerBetaLimit : beta,
	radius: upperRadiusLimit
}