<template>
	<div ref="mapOverlay" class="flex flex-col absolute bottom-0">
		<div class="bg-dark w-[96px] uppercase rounded-t-lg text-[14px] text-light-blue">
			<div class="pl-4 pt-[6px] leading-[20px]">{{ paginationText }}</div>
			<!-- <div class="pl-4 pt-[6px] leading-[20px]">Node X</div> -->
		</div>
		<div class="bg-dark text-white rounded-r-lg rounded-b-lg px-4 pt-2 max-w-[575px]">
			<div>
				<div ref="titleRef" :style="{ fontSize: titleFontSize + 'px', whiteSpace: 'nowrap', lineHeight: '35px' }"
					class="uppercase text-light-blue overflow-hidden text-ellipsis font-semibold">
					{{ selectedNode?.title }}
				</div>
				<hr class="hr bg-light-blue opacity-25">
			</div>
			<div class="flex flex-col gap-5 py-4 pr-2 flex-1 max-h-[calc(100vh-520px)] overflow-auto">
				<div v-for="(section, index) in selectedNode?.sections" :key="index">
					<div class="uppercase text-light-blue text-[15px] font-semibold">.{{ section.title }}</div>
					<div class="text-[14px] font-light" v-html="section.content"></div>
				</div>
			</div>
			<UiNavigationMenu
				:showPreviousNode="showPreviousNode"
				:showNextNode="showNextNode"
				@closeOverlay="closeOverlay()" 
				@nextNode="nextNode()" 
				@previousNode="previousNode()"/>
		</div>
	</div>
</template>

<script setup>
const { unselectNode, selectedNode, paginationText, nextNode, previousNode, showPreviousNode, showNextNode } = useMap()
const { updateURLParams } = useRouterManager()
const { setClientRect } = useMapOverlay()
const mapOverlay = ref(null)

watch(() => mapOverlay, mapOverlay => {
		const clientRect = mapOverlay?.value?.getBoundingClientRect?.()
		clientRect && setClientRect(clientRect)
}, { immediate: true, deep: true })

const titleRef = ref(null)
const titleFontSize = ref(18)

const adjustFontSize = () => {
	if (titleRef.value) {
		nextTick(() => {
			const titleElement = titleRef.value
			const maxWidth = titleElement.parentElement.offsetWidth

			let fontSize = 18;
			titleElement.style.fontSize = `${fontSize}px`
			titleElement.style.whiteSpace = 'nowrap'

			while (titleElement.scrollWidth > maxWidth && fontSize > 12) {
				fontSize -= 1
				titleElement.style.fontSize = `${fontSize}px`
			}

			titleFontSize.value = fontSize
		})
	}
}

const closeOverlay = () => {
	unselectNode?.()
	updateURLParams({ section: 'Map' })
}


onMounted(() => {
	adjustFontSize()
})

watch(() => paginationText, () => {
	adjustFontSize()
})
</script>
