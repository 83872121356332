<template>
	<svg :width="starSize" :height="starSize" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg">
		<path :fill="fillColor" d="M20.3064 1.59872L25.5098 11.1853L36.2034 13.1928C37.3506 13.3976 37.8013 14.7906 36.9819 15.6509L29.484 23.5578L30.9181 34.3734C31.0819 35.5206 29.8938 36.3809 28.8285 35.8893L18.9953 31.2189L9.16204 35.8893C8.09677 36.3809 6.90859 35.5206 7.07248 34.3734L8.50649 23.5578L1.00866 15.6509C0.189224 14.7906 0.680883 13.3976 1.78712 13.1928L12.4808 11.1853L17.6842 1.59872C18.2578 0.574514 19.6918 0.574514 20.2654 1.59872H20.3064Z" />
	</svg>
</template>

<script lang="ts" setup>

const props = defineProps({
	fillColor: {
		type: String,
		default: '#646464',
	},
	size: {
		type: String,
		default: 'small'
	}
})

const starSize = computed(() => {
	return props.size === 'large' ? 50 : 42 
})
</script>

<style scoped></style>
