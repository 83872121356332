
export default function useRouterManager() {
  const route = useRoute()
  const router = useRouter()
  return {
    updateURLParams: (params: any) => updateURLParams(router, route, params),
    whenRouteURLChanges: (callback, opts: { immediate: boolean }) => {
      watch(
        () => route.query,
        () => callback?.(route),
        opts
      )
    }
  }
}

const updateURLParams = (router: any, route: any, params: any) => {
  const query = removeEmptyAttrs({
      ...route.query,
      ...params
  })
  router.push({ path: route.path, query })
}

function removeEmptyAttrs(obj: any) {
  Object.keys(obj).forEach(key => {
      const val = obj[key]
      if(val == null || val?.trim() === '') delete obj[key]
  })
  return obj
}