<template>
	<div>
		<transition name="slide-fade" mode="out-in">
			<component :is="currentViewComponent" :questions="questions" :current-question-index="currentQuestionIndex"
				:isCompleted="isCompleted" :score="score" :bestScore="bestScores[productId]" :company="product?.subtitle"
				@startTrivia="startTrivia" @showLeaderboard="showLeaderboard" @resetQuiz="resetQuiz" @updateScore="updateScore"
				@nextQuestion="nextQuestion" :key="currentView" />
		</transition>
	</div>
</template>

<script lang="ts" setup>
import QuizIntro from '@/components/quiz/quiz-intro.vue'
import QuizTrivia from '@/components/quiz/quiz-trivia.vue'
import QuizLeaderboard from '@/components/quiz/quiz-leaderboard.vue'

const route = useRoute()
const productId = route.params.id
const { questions } = useQuizzes(productId)
const { findById } = useProducts()
const product = findById(productId)
const currentView = ref('intro')
const currentQuestionIndex = ref(0)
const isCompleted = ref(false)
const score = ref(0)
const bestScores = ref({})

const currentViewComponent = computed(() => {
	switch (currentView.value) {
		case 'intro':
			return QuizIntro
		case 'trivia':
			return QuizTrivia
		case 'leaderboard':
			return QuizLeaderboard
		default:
			return QuizIntro
	}
})

function resetQuizState() {
	isCompleted.value = false
	currentQuestionIndex.value = 0
	score.value = 0
	currentView.value = 'intro'
}

defineExpose({
	resetQuizState
})

function startTrivia() {
	if (questions.value.length > 0) {
		resetQuizState()
		currentView.value = 'trivia'
	} else {
		console.error('No questions available to start the trivia')
	}
}

function resetQuiz() {
	resetQuizState()
}

function showLeaderboard() {
	currentView.value = 'leaderboard'
}

function nextQuestion() {
	if (currentQuestionIndex.value < questions.value.length - 1) {
		currentQuestionIndex.value++
	} else {
		if (score.value > (bestScores.value[productId] || 0)) {
			bestScores.value[productId] = score.value
			localStorage.setItem('bestScores', JSON.stringify(bestScores.value))
		}
		isCompleted.value = true
		currentView.value = 'intro'
	}
}

function updateScore(isCorrect) {
	if (isCorrect) {
		score.value++
	}
}

onMounted(() => {
	const savedBestScores = localStorage.getItem('bestScores')
	if (savedBestScores) {
		bestScores.value = JSON.parse(savedBestScores)
	}
})
</script>

<style lang='stylus' scoped>
	@import '@/assets/css/transitions.css'
</style>
