export const quizzes = {
	product0: [
		{
			question: "What is the primary ingredient in Susgrainable Baking Flour?",
			options: ["Malted Barley", "Wheat", "Rice"],
			correctAnswer: "Malted Barley"
		},
		{
			question: "Where is the malted barley used in Susgrainable Baking Flour sourced from?",
			options: ["Eastern Canada", "Western Canada", "Southern USA"],
			correctAnswer: "Western Canada"
		},
		{
			question: "What is unique about the production process of Susgrainable Baking Flour?",
			options: ["It uses recycled wheat grains.", "It utilizes Brewer's Spent Grain (BSG) from the brewing industry.", "It is processed with rice flour."],
			correctAnswer: "It utilizes Brewer's Spent Grain (BSG) from the brewing industry."
		}
	],
	product1: [
		{
			question: "What type of cotton is used in the production of the printed T-shirt and where is it sourced from?",
			options: ["Conventional cotton from India", "100% organic cotton from Texas, USA", "Recycled cotton from China"],
			correctAnswer: "100% organic cotton from Texas, USA"
		},
		{
			question: "Which eco-friendly feature is associated with the dyes and chemicals used in the T-shirt's production?",
			options: ["They are certified by GOTS and are non-toxic", "They are petroleum-based and sourced from China", "They contain heavy metals for vibrant colors"],
			correctAnswer: "They are certified by GOTS and are non-toxic"
		},
		{
			question: "What material is used for the packaging of the T-shirt and where is it sourced from?",
			options: ["Single-use plastic from India", "100% recyclable paper from the USA", "Biodegradable plastic from Germany"],
			correctAnswer: "100% recyclable paper from the USA"
		},
	],
}

export function useQuizzes(productId) {
	const questions = computed(() => quizzes[productId] || [])
	return {
		questions,
	}
}
