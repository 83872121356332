<template>
	<div ref="scrollContainer" class="scroll-container flex flex-col h-[calc(100dvh-280px)] overflow-auto overflow-x-hidden pr-[10px] mr-[-10px] justify-between">
		<UiQuizHeader :title="title" />
		<transition name="slide-fade" mode="out-in" @after-enter="scrollToTop">
			<div :key="currentQuestionIndex" class="flex flex-col flex-1 justify-between gap-6">
				<div class="flex flex-col flex-1 justify-evenly">
					<div class="text-[18px] font-light text-center p-4">
						{{ currentQuestion.question }}
					</div>
					<div class="flex flex-col gap-3">
						<UiButton v-for="(option, index) in currentQuestion.options" :key="index" height="auto"
							:class="[selectedOptionIndex !== null && index === selectedOptionIndex ? isCorrect ? 'bg-lime' : 'bg-red' : '']"
							class="rounded-3xl !font-light normal-case min-h-[48px] py-2 px-4 !text-[18px]" @click="handleOptionClick(index)">
							{{ option }}
						</UiButton>
					</div>
				</div>
			</div>
		</transition>
		<UiProgressDots class="mt-4" :totalQuestions="questions.length" :currentQuestionIndex="currentQuestionIndex" />
	</div>
</template>

<script lang="ts" setup>

const props = defineProps({
	questions: {
		type: Array,
		required: true,
		default: () => []
	},
	currentQuestionIndex: {
		type: Number,
		required: true
	}
})

const emit = defineEmits(["nextQuestion", "updateScore"])

const selectedOptionIndex = ref(null)
const isCorrect = ref(false)
const title = 'Trivia'

const currentQuestion = computed(
	() => props.questions[props.currentQuestionIndex]
)

const scrollContainer = ref<HTMLElement | null>(null)

function scrollToTop() {
	nextTick(() => {
		if (scrollContainer.value) {
			scrollContainer.value.scrollTop = 0
		}
	})
}

function handleOptionClick(index) {
	selectedOptionIndex.value = index
	isCorrect.value = currentQuestion.value.correctAnswer === currentQuestion.value.options[index]

	emit("updateScore", isCorrect.value)

	setTimeout(() => {
		selectedOptionIndex.value = null
		emit("nextQuestion")
	}, 350)
}

watch(
	() => props.questions,
	() => { selectedOptionIndex.value = null }
)
</script>

<style lang="stylus" scoped>
	@import '@/assets/css/transitions.css'
	
	.scroll-container 
		scroll-behavior smooth
	
</style>
