<template>
	<div class="p-4 flex flex-col h-full gap-2">
		<UiProductHeader :product="selectedProduct?.title" :company="selectedProduct?.subtitle" :category="selectedProduct?.category" :id="selectedProduct?.id"
			:score="selectedProduct?.score" :image="selectedProduct?.image" :icons="selectedProduct?.icons" />
		<transition-group
			tag="div"
			class="relative flex flex-col h-[calc(100%-188px)]"
			name="slide"
			mode="out-in"
			@before-enter="beforeEnter"
			@enter="enter"
			@leave="leave"
			@after-leave="afterLeave"
		>
			<div v-if="currentComponent === 'Story' || currentComponent === 'Map'" class="absolute inset-0 slide-container">
				<Map :onFocusNode="setStoryTab" :onUnfocusNode="setMapTab" />
			</div>
			<div v-if="currentComponent === 'Facts'" class="absolute inset-0 slide-container">
				<Facts :productFacts="selectedProduct?.facts" />
			</div>
			<div v-if="currentComponent === 'Quiz'" class="absolute inset-0 slide-container">
				<Quiz ref="quizComponent" />
			</div>
		</transition-group>
		<UiProductMenu :current="currentComponent" @select="handleSelect" />
	</div>
</template>

<script lang="ts" setup>
const routerManager = useRouterManager()
const route = useRoute()
const { selectProductById, selectedProduct } = useProduct()
const sections = [ 'Map', 'Facts', 'Quiz', 'Story']
const STORY_TAB = 'Story'
const MAP_TAB = 'Map'
const transitionDirection = ref('right')
const currentComponent = ref('Map')
const quizComponent = ref(null)

selectProductById(route.params.id)

const getKey = componentName => {
	const index = sections.findIndex(x => x === componentName)

	return index === -1
		? 0
		: index
}

let previousKey = 1
routerManager.whenRouteURLChanges((route) => {
		currentComponent.value = sections.includes(route.query?.section) ? route.query?.section : 'Map'
		currentComponent.value === 'Story' && useMap()?.focusFirstNode?.()
		if(currentComponent.value === 'Map') useMap()?.restartCameraPosition?.()
	}, 
	{ immediate: true }
)

const handleSelect = (componentName) => {
	routerManager.updateURLParams({ section: componentName })

	const key = getKey(componentName)
	transitionDirection.value =
		key > previousKey
			? 'right'
			: 'left'
	previousKey = key
	currentComponent.value = componentName
	if (componentName === 'Quiz' && quizComponent.value) {
		quizComponent.value.resetQuizState()
	}
	({
		'Story': () => setStoryTabAndFocusFirst(),
		'Map': () => setMapTab()
	})?.[componentName]?.()
}

const setStoryTab = () => {
	currentComponent.value = STORY_TAB
}

const setStoryTabAndFocusFirst = () => {
	currentComponent.value = STORY_TAB
	useMap()?.focusFirstNode?.()
}

const setMapTab = () => {
	currentComponent.value = MAP_TAB
}

const beforeEnter = (el) => {
	el.style.transform =
		transitionDirection.value === 'right'
			? 'translateX(100%)'
			: 'translateX(-100%)'
}

const enter = (el, done) => {
	requestAnimationFrame(() => {
		el.style.transition = 'transform 0.5s ease'
		el.style.transform = 'translateX(0%)'
		el.addEventListener('transitionend', done)
	})
}

const leave = (el, done) => {
	el.style.transition = 'transform 0.5s ease'
	el.style.transform =
		transitionDirection.value === 'right'
			? 'translateX(-100%)'
			: 'translateX(100%)'
	el.addEventListener('transitionend', done)
}

const afterLeave = (el) => {
	el.style.transition = ''
	el.style.transform = ''
}
</script>

<style lang="stylus" scoped>
.slide-container 
	position absolute
	width 100%
	height 100%
	top 0
	left 0
</style>
