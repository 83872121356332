<template>
	<div class="flex flex-col gap-4 overflow-auto pr-[10px] mr-[-10px] h-[calc(100dvh-280px)]">
		<div v-for="(section, index) in sections" :key="index" class="flex flex-col gap-2">
			<div class="text-[14.5px] text-white bg-dark rounded px-3 h-[34px] flex justify-between items-center cursor-pointer"
				@click="toggleSection(index)">
				{{ section.title }}
				<img :class="{ 'rotate-180': visibleSections[index], 'rotate-0': !visibleSections[index] }"
					src="@/assets/img/icons/caret.svg" alt="caret" class="transition-transform" />
			</div>
			<transition name="expand" @enter="enter" @leave="leave">
				<div v-if="visibleSections[index] && (!section?.image || section?.imageLoaded)" class="px-2">
					<img v-if="section?.image" :src="section?.image" class="w-full px-2 py-3" />
					<div v-else-if="section.type === 'text'" class="text-[14px] text-dark">
						{{ section.textContent }}
					</div>
				</div>
			</transition>
		</div>
	</div>
</template>

<script lang="ts" setup>
const props = defineProps({
	productFacts: {
		type: Object,
		required: true,
	},
})

watch(() => props.productFacts, (productFacts) => {
	const sections = props.productFacts?.sections ?? []
	if(sections?.length > 0)
		sections.forEach(async (s) => {
			if(s?.image && !s?.imageLoaded){
				const res = await preloadImage(s?.image)
				if(res) s.imageLoaded = true
			}
		})
}, { immediate: true})

const visibleSections = ref([true, false])

function toggleSection(index) {
	visibleSections.value[index] = !visibleSections.value[index]
}

function preloadImage(url) {
	return new Promise((resolve) => {
		const img = new Image();
		img.src = url;
		img.onload = () => {
			resolve(true);
		};
	});
}

function enter(el) {
	const height = el.scrollHeight
	el.style.height = '0'
	el.offsetHeight
	el.style.transition = 'height 0.35s ease'
	el.style.height = height + 'px'
}

function leave(el) {
	el.style.height = el.scrollHeight + 'px'
	el.offsetHeight
	el.style.transition = 'height 0.35s ease'
	el.style.height = '0'
}

const sections = computed(() => {
	return props.productFacts?.sections ?? []
})
</script>

<style lang="stylus" scoped>
.rotate-180 
	transform rotate(-180deg)

.rotate-0 
	transform rotate(0deg)

.transition-transform 
	transition transform 0.35s ease

.pb-full
	padding-bottom 100%

.expand-enter-active, .expand-leave-active
	overflow hidden

.expand-enter-to, .expand-leave-to
	overflow hidden
</style>
