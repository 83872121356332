<template>
	<div v-for="(starPosition, index) in starPositions" :key="index" :class="[starPosition, 'star-wrapper']"
		class="flex flex-col justify-center relative">
		<UiStar :fillColor="'#646464'" :size="'small'" />
		<transition name="fade-and-scale" mode="out-in">
			<div v-if="index < animatedScore" class="absolute bg-star-filled">
				<UiStar :fillColor="'#ECC785'" :size="'large'" />
			</div>
		</transition>
	</div>
</template>

<script lang="ts" setup>
const props = defineProps({
	score: {
		type: Number,
		default: 0
	},
	animate: {
		type: Boolean,
		default: false
	}
})

const starPositions = [
	'rotate-[-15deg]',
	'mt-[-32px]',
	'rotate-[15deg]'
]

const animatedScore = ref(0)

watch(() => props.score, (newScore) => {
	if (props.animate) {
		animatedScore.value = 0;
		for (let i = 1; i <= newScore; i++) {
			setTimeout(() => {
				animatedScore.value = i
			}, i * 500)
		}
	} else {
		animatedScore.value = newScore
	}
}, { immediate: true })
</script>

<style lang="stylus" scoped>
.star-wrapper
	position relative

.bg-star-filled
	border-radius 50%
	padding 8px
	background-color #233C43
	position absolute
	top -8px
	left -12px

.fade-and-scale-enter-active, .fade-and-scale-leave-active
	transition opacity 0.35s ease-in-out, transform 0.35s ease-in-out

.fade-and-scale-enter-from, .fade-and-scale-leave-to
	transform scale(0.5)
	opacity 0

.fade-and-scale-enter-to, .fade-and-scale-leave-from
	opacity 1
	transform scale(1)
</style>
