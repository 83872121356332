let state = null

export default function usMapOverlay(){
  if(state) return state
  const clientRect = ref(null)
  state = {
    clientRect,
    setClientRect: _clientRect => {
      clientRect.value = _clientRect
    }
  } 
  return state
}
