<template>
	<div class="flex flex-col gap-2">
		<hr class="hr bg-light-blue opacity-[25%]" />
		<div  class="flex justify-between mb-2">
			<div :style="{ visibility: showPreviousNode ? 'visible' : 'hidden' }"
				class="bg-[url('assets/img/icons/arrow-prev.svg')] bg-[length:14px] size-[24px] icon-sm bg-light-orange"
				@click="emit('previousNode')">
			</div>
			<div class="bg-[url('assets/img/icons/exit.svg')] bg-[length:14px] size-[24px] icon-sm bg-light-orange"
				@click="emit('closeOverlay')">
			</div>
			<div :style="{ visibility: showNextNode ? 'visible' : 'hidden' }"
				class="bg-[url('assets/img/icons/arrow-next.svg')] bg-[length:14px] size-[24px] icon-sm bg-light-orange"
				@click="emit('nextNode')">
			</div>
		</div>
	</div>
</template>

<script setup>
const emit = defineEmits(['closeOverlay', 'nextNode', 'previousNode'])

defineProps({
	showPreviousNode: {
		type: Boolean,
		required: true
	},
	showNextNode: {
		type: Boolean,
		required: true
	}
})

</script>

<style lang="stylus" scoped>
.icon-sm 
	transition background-color 0.2s ease

.icon-sm:active 
	background-color darken(#EFB84E, 15%)

</style>
