<template>
	<div class="h-full flex justify-center relative">
		<UiLoader :show="showMap" class="h-full relative rounded-lg">
			<canvas class="canvas h-full w-[calc(100vw-32px)] max-w-full" ref="renderCanvas"></canvas>
			<transition name="overlay-slide" mode="out-in">
				<div v-if="selectedNode" class="absolute bottom-[16px] left-[16px] right-[16px] flex w-[calc(90%)] max-w-[calc(90%)] h-[calc(60%)]">
					<transition :name="transitionName" mode="out-in">
						<UiMapOverlay :key="selectedNode?.id" v-if="selectedNode" class="w-[calc(100%+64px)] max-w-[calc(100vw-64px)]"/>
					</transition>
				</div>
			</transition>
		</UiLoader>
	</div>
</template>

<script lang="ts" setup>
import '@babylonjs/loaders/glTF';

const { selectedNode, showMap, loadMap, unMounted, focusFirstNode } = useMap()
const { selectedProduct } = useProduct()
const renderCanvas = ref(null)
const reloadMap = () => loadMap({ canvas: unref(renderCanvas), product: unref(selectedProduct) })

watch(() => selectedProduct, () => reloadMap())
onMounted(() => reloadMap())
onUnmounted(() => unMounted())
let lastNodeId = 1

const emit = defineEmits(['focusNode', 'unfocusNode'])

defineProps({
	mapUrl: String,
	product: Object
})

const transitionName = computed(() => {
	const transition =  selectedNode?.value?.id > lastNodeId ? 'overlay-carousel-next' : 'overlay-carousel-prev'
	lastNodeId = selectedNode?.value?.id
	return transition
})

</script>
<style lang="stylus" scoped>
.canvas
	touch-action none
	outline none


.overlay-slide-enter-active,
.overlay-slide-leave-active
	transition transform 1s ease, opacity .35s ease

.overlay-slide-enter-from
	transform translateY(105%)
	opacity 0

.overlay-slide-leave-to
	transform translateY(105%)
	opacity 0

.overlay-carousel-next-enter-active,
.overlay-carousel-next-leave-active 
	transition transform .35s ease, opacity .35s ease

.overlay-carousel-next-enter-from 
	transform rotate(12deg) translate(50%, 0%)
	opacity 0

.overlay-carousel-next-leave-to 
	transform rotate(-12deg) translate(-50%, 0%)
	opacity 0

.overlay-carousel-prev-enter-active,
.overlay-carousel-prev-leave-active 
	transition transform .35s ease, opacity .35s ease

.overlay-carousel-prev-enter-from 
	transform rotate(-12deg) translate(-50%, 0%) 
	opacity 0

.overlay-carousel-prev-leave-to 
	transform rotate(12deg) translate(50%, 0%)
	opacity 0
</style>
